import React, { ReactNode, useCallback } from 'react'
import { HTMLProps } from 'react'
import cn from 'classnames'
import s from './MenuItem.module.scss'
import { useRouter } from 'next/router'

type subNavName = 'Home' | 'Products' | 'FSI Solution' | 'AboutUs' | 'Resources' | 'News' | 'lang' | ''
export type IMenuItemProps = HTMLProps<HTMLDivElement> & {
  isCheck?: boolean //是否选中状态
  label: ReactNode
  icon?: ReactNode
  href?: string
  desc?: string
  name?: subNavName
  onClickMenu?: (href: string, name: subNavName) => void
}
// 只包含中文和英文的路径
const pathOnlyZhAndEn = ['/blog', '/news', '/events', '/marketupdates']
const MenuItem = (props: IMenuItemProps) => {
  const router = useRouter()
  const { isCheck = false, icon, label, href = '', desc, name = '', onClickMenu } = props

  const menuClick = useCallback(() => {
    onClickMenu?.(href, name)
    if (pathOnlyZhAndEn.indexOf(href) != -1) {
      if (router.locale == 'en' || (router.locale == 'zh' && href != '/events')) {
        router.push(href)
      } else {
        window.location.href = '/' + 'en' + href
      }
    } else {
      router.push(href)
    }

  }, [href, name, onClickMenu, router])
  return (
    <div className={cn(s.menu_box, { [s.menu_box_hover]: href, [s.menu_box_Ar]: router.locale == 'ar' })}>
      <a className={s.label_warpper} onClick={() => menuClick()} target={name ? '_self' : '_blank'}>
        <div className={s.label}>
          {icon ? icon : null}
          <span className={cn(s.label_a, { [s.label_a_hover]: href }, { [s.labelCheck]: isCheck })}>{label}</span>
        </div>
        {desc && <div className={s.desc}>{desc}</div>}
      </a>

    </div>
  )
}

export default React.memo(MenuItem)
