/**
 * Create by chenxiuting
 * Description: config
 */

export enum LocalMap {
  'zh' = '繁体中文',
  'en' = 'English',
  'ja' = '日本語',
  'kr' = '한국인',
  'fr' = 'Français',
  'pt' = 'Português',
  'ar' = 'اللغة العربية',
  'es' = 'Español'
}

export const langMap: Record<string, string> = {
  zh: 'zh-TW',
  en: 'en-US',
  ja: 'ja-JP',
  kr: 'ko-KR',
  fr: 'fr-FR',
  pt: 'pt-PT',
  ar: 'ar-EG',
  es: 'es-ES'
}

export const localArray = Object.entries(LocalMap).map(([value, label]) => {
  return {
    value,
    label
  }
})

export type LocalType = 'zh' | 'en' | 'ja' | 'kr' | 'fr' | 'pt' | 'ar' | 'es'
