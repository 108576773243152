import { useStore } from '@/utils/store'
import { useEffect } from 'react'

export function useScroll(callback: unknown, deps = []) {
  const lenis = useStore(({ lenis }: any) => lenis)

  useEffect(() => {
    if (!lenis) return
    lenis.on('scroll', callback, { passive: true })
    lenis.emit()

    return () => {
      lenis.off('scroll', callback)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lenis, callback, [...deps]])
}
