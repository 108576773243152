import { AppContext, AppProps } from 'next/app'
import { appWithTranslation } from 'next-i18next'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import Layout from '@/components/Layout'
import 'aos/dist/aos.css'
import '../styles/global.scss'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { useStore } from '@/utils/store'
import { useScroll } from '@/hooks/use-scroll'
import Lenis from '@studio-freight/lenis'
import { useFrame } from '@studio-freight/hamo'
import raf from '@studio-freight/tempus'
import { ToastContainer } from '@/components/Toast'
const arrLang = ['zh', 'en', 'ja', 'kr', 'fr', 'pt', 'ar', 'es']

function MyApp({ Component, pageProps }: AppProps) {
  const [isH5, setIsH5] = useState<boolean | null>(pageProps.isMobile)
  const [lenis, setLenis] = useStore((state: any) => [state.lenis, state.setLenis])
  const layoutRef = useRef<HTMLDivElement | null>(null)

  useFrame((time: any) => {
    lenis?.raf(time)
  })
  useScroll(ScrollTrigger.update)
  useEffect(() => {
    if (lenis) {
      ScrollTrigger.refresh()
      lenis?.start()
    }
  }, [lenis])
  useEffect(() => {
    const lenis = new Lenis({
      orientation: 'vertical', // vertical, horizontal
      gestureOrientation: 'vertical', // vertical, horizontal, both
      smoothWheel: true,
      wheelMultiplier: 1,
      touchMultiplier: 2,
      infinite: false
    })
    window.lenis = lenis
    setLenis(lenis)
    gsap.registerPlugin(ScrollTrigger)
    ScrollTrigger.defaults({ markers: process.env.NODE_ENV === 'development' })
    gsap.ticker.lagSmoothing(0)
    gsap.ticker.remove(gsap.updateRoot)
    // 动态引入第三方插件
    raf.add((time: number) => {
      gsap.updateRoot(time / 1000)
    }, 0)

    window.history.scrollRestoration = 'manual'
    return () => {
      lenis.destroy()
      setLenis(null)
    }
  }, [setLenis])

  const router = useRouter()
  const { locale = 'en' } = router
  const handleResize = useCallback(() => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    // 动态引入第三方插件
    import('aos')
      .then((AOS) => {
        AOS.refresh()
        AOS.init({
          offset: width <= 800 ? 20 : 40, //早于指定时间触发动画
          duration: width <= 800 ? 400 : 1000, //持续时间
          delay: 0,
          once: false //是否仅触发一次
        })
      })
      .catch((error) => {
        console.error('An error occurred while loading mathjs:', error)
      })
    setIsH5(width <= 800)
  }, [])
  useEffect(() => {
    // 来源网址
    const from = localStorage.getItem('from')
    if (!from) {
      localStorage.setItem('from', String(`${location.href}`))
    }
    handleResize()
    window.addEventListener('resize', handleResize, { passive: true })
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize, locale])

  useEffect(() => {
    let isFill = false
    const handleRouteStart = (e: string) => {
      //  router.pathname 当前页面
      const beforeEspeciallyUrl = router.pathname.includes('/blog') || router.pathname.includes('/news') || router.pathname.includes('/marketupdates') || router.pathname.includes('/events')
      //e 将要跳转的页面
      const afterEspeciallyUrl = e.includes('/blog') || e.includes('/news') || e.includes('/marketupdates') || e.includes('/events')
      //从其他页面到特殊页面 要存下来当前的语言

      if (!beforeEspeciallyUrl && afterEspeciallyUrl) {
        localStorage.setItem('currentLang', locale || 'en')
      }
      //从特殊页面到其他页面
      if (beforeEspeciallyUrl && !afterEspeciallyUrl) {
        const beforeLocal = localStorage.getItem('currentLang') || ''
        let afterPathLang = beforeLocal

        if (locale == 'zh' && beforeLocal != locale) {
          afterPathLang = 'zh'
        } else if (beforeLocal != locale) {
          afterPathLang = beforeLocal
        }
        localStorage.setItem('currentLang', afterPathLang || 'en')

        const path = arrLang.indexOf(e.split('/')[1]) != -1 ? e.slice(3) : e

        if (isFill) {
          isFill = false
          router.push({
            pathname: `/${afterPathLang}/${path}`
          })
        }
      }
      NProgress.start()
    }
    const handleRouteChange = (e: { preventDefault: () => void }) => {
      isFill = true
      e.preventDefault && e.preventDefault()
      // Router.push('/新的URL');
      lenis?.start()
      NProgress.done(false)
      const hash = router.asPath.split('#')[1]
      if (hash) {
        const element = document.getElementById(hash)
        if (element) {
          if (router.asPath.includes('/dex#form')) {
            setTimeout(() => {
              //dex有一屏幕有计算高度 为了解决sarafi浏览器锚点跳转不准问题
              element.scrollIntoView({ behavior: 'smooth' })
            }, 500)
          } else {
            element.scrollIntoView({ behavior: 'smooth' })
          }
        }
      }
    }
    router.events.on('routeChangeStart', handleRouteStart)
    router.events.on('routeChangeError', () => {
      NProgress.done(false)
    })
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('routeChangeStart', handleRouteStart)
      router.events.off('routeChangeError', () => {
        NProgress.done(false)
      })
    }
  }, [lenis, locale, router, router.asPath, router.events])
  const getFooterHeight = useCallback(() => {
    return layoutRef.current?.clientHeight
  }, [])

  return (
    <>
      <Layout isH5={isH5} type={router.pathname == '/'} ref={layoutRef} hiddenHeader={pageProps?.hiddenHeader}>
        <Component {...pageProps} isH5={isH5} getFooterHeight={getFooterHeight} />
      </Layout>
      <ToastContainer {...pageProps} />
    </>
  )
}

MyApp.getInitialProps = async ({ Component, ctx }: AppContext) => {
  const userAgent = ctx.req?.headers['user-agent']
  let isMobile = false;
  const mobileKeywords = ["Android", "iPhone", "iPad", "Windows Phone"];
  mobileKeywords.forEach(keyword => {
    if (userAgent?.includes(keyword)) {
      isMobile = true;
      return
    }
  });
  let pageProps = {} as any;
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }
  pageProps.isMobile = isMobile
  return { pageProps }
}

export default appWithTranslation(MyApp)
