import Alert from './alert.svg'
import Arrow from './arrow.svg'
import Checkbox from './checkbox.svg'
import DifficultyIcon from './difficulty-icon.svg'
import Filter from './filter-icon.svg'
import Error from './form_error.svg'
import Success from './form_success.svg'
import Pdf from './icon_pdf.svg'
import Add from './icon-add.svg'
import Close from './icon-close.svg'
import DownLoad from './icon-downLoad.svg'
import Expand from './icon-expand.svg'
import Form from './icon-form.svg'
import LeftArrow from './icon-left-arrow.svg'
import Locale from './icon-locale.svg'
import Omit from './icon-omit.svg'
import Position from './icon-position.svg'
import PositionNum from './icon-positionNum.svg'
import RightArrow from './icon-right-arrow.svg'
import Right from './icon-right.svg'
import Search from './icon-search.svg'
import Sure from './icon-sure.svg'
import TimeEvent from './icon-time-event.svg'
import Tip from './icon-tip.svg'
import Lading from './lading.svg'
import LogoLeft from './logo-left.svg'
import LogoRight from './logo-right.svg'
import LogoSm from './logo-sm.svg'
import LogoWhite from './logo-white.svg'
import Logo from './logo.svg'
import NoData from './no-data.svg'
import PlayWhite from './play_white.svg'
import QuotLeft from './quot-left.svg'
import QuotRight from './quot-right.svg'
import SelectArrow from './select_arrow.svg'
import SingleLogo from './single-logo.svg'
import ToastError from './toast-error.svg'
import ToastSuccess from './toast-success.svg'
import ToastWarnning from './toast-warnning.svg'
import Top from './top.svg'
import ArrowRight from './whiteArrow.svg'
import ChevronUp from './ChevronUp.svg'
import ChevronDown from './ChevronDown.svg'
import ChevronLeft from './ChevronLeft.svg'
import ChevronRight from './ChevronRight.svg'
import ChevronRightBig from './ChevronRightBig.svg'
import Loading from './lading.svg'
import X from './close.svg'
import Bitwind from './bitwind.svg'
import WhiteLogo from './white-logo.svg'

export {
  WhiteLogo,
  Bitwind,
  X,
  Alert,
  Arrow,
  Checkbox,
  DifficultyIcon,
  Filter,
  Error,
  Success,
  Pdf,
  Add,
  Close,
  DownLoad,
  Expand,
  Form,
  LeftArrow,
  Locale,
  Omit,
  Position,
  PositionNum,
  RightArrow,
  Right,
  Search,
  Sure,
  TimeEvent,
  Tip,
  Lading,
  LogoLeft,
  LogoRight,
  LogoSm,
  LogoWhite,
  Logo,
  NoData,
  PlayWhite,
  QuotLeft,
  QuotRight,
  SelectArrow,
  SingleLogo,
  ToastError,
  ToastSuccess,
  ToastWarnning,
  Top,
  ArrowRight,
  ChevronUp,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronRightBig,
  Loading
}
