/**
 * Create by chenxiuting
 * Description: useLocale
 */
import { useTranslation } from 'next-i18next'
import { crc32 } from 'crc'
// import { TOptions } from 'i18next'

const useLocale = () => {
  const i18n = useTranslation()

  const t = (key: string, options?: any) => {
    const hashKey = `K${crc32(key).toString(16)}`
    const value = i18n.t(hashKey, options)
    if (value === hashKey) {
      return key
    }
    return value
  }

  return { t }
}

export default useLocale
