import { create } from 'zustand'

export const useStore = create((set, get) => ({
  lenis: undefined,
  setLenis: (lenis: any) => set({ lenis }),
  overflow: true,
  setOverflow: (overflow: any) => set({ overflow }),
  triggerTransition: '',
  setTriggerTransition: (triggerTransition: any) => set({ triggerTransition }),
  thresholds: {},
  addThreshold: ({ id, value }: any) => {
    const thresholds = { ...(get() as any).thresholds }
    thresholds[id] = value

    set({ thresholds })
  }
}))
