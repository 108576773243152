import React, { useEffect, useMemo, useState } from 'react'
import EventEmitter from 'events'
import styles from './Toast.module.scss'
import { CSSTransition } from 'react-transition-group'
import { useRouter } from 'next/router'
import ToastSuccess from '../../icons/toast-success.svg'
import ToastError from '../../icons/toast-error.svg'
import ToastWarning from '../../icons/toast-warnning.svg'
import cn from 'classnames'

const event = new EventEmitter()

export const ToastContainer = () => {
  const { locale } = useRouter()

  const [visible, setVisible] = useState(false)

  const [options, setOptions] = useState<ToastOptions>()

  useEffect(() => {
    event.on('visible', (options) => {
      setVisible(true)
      setOptions(options)
    })
  }, [])

  const ToastIcon = useMemo(() => {
    if (!options?.type) return null
    const iconMap = {
      success: <ToastSuccess className={styles.toastIcon} />,
      error: <ToastError className={styles.toastIcon} />,
      warn: <ToastWarning className={styles.toastIcon} />
    }
    return iconMap[options.type]
  }, [options])

  useEffect(() => {
    let timer: NodeJS.Timer
    if (visible) {
      timer = setTimeout(() => {
        setVisible(false)
      }, options?.duration ?? 2000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [options, visible])

  return (
    <div className={styles.toastContainer}>
      <CSSTransition
        timeout={300}
        in={visible}
        classNames={{
          enter: styles.toastAnimatedEnter,
          enterActive: styles.toastAnimatedEnterActive,
          exit: styles.toastAnimatedExit,
          exitActive: styles.toastAnimatedExitActive
        }}
        unmountOnExit
        mountOnEnter
      >
        <div className={cn(styles.toast, { [styles.toastAr]: locale == 'ar' })}>
          {ToastIcon}
          {options?.content}
        </div>
      </CSSTransition>
    </div>
  )
}

interface ToastOptions {
  content: string
  type: 'success' | 'error' | 'warn'
  duration?: number
}

export const Toast = (options: ToastOptions) => {
  event.emit('visible', options)
}

Toast.success = (content: string) => {
  event.emit('visible', {
    type: 'success',
    content
  })
}

Toast.error = (content: string) => {
  event.emit('visible', {
    type: 'error',
    content
  })
}
Toast.warn = (content: string) => {
  event.emit('visible', {
    type: 'warn',
    content
  })
}
